import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
export default ({ data }) => {
  const { kasino } = data
  const { databaseId, seo, slug, ACFKasino, content } = kasino

  return (
    <Layout bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
      <GatsbySeo 
        title={seo.title} description={seo.metaDesc} 
        canonical={`https://www.pelisivustot.fi/${slug}/`}
      />
      <article className={`casino-${databaseId} kokemuksia page type-page status-publish hentry`} id={`casino-${databaseId}`}>
        <header className="casino-header">
        <h1 className="entry-title">{ACFKasino.kasinonNimi}</h1>
          <div className="top">
            <div className="logo">
              <img alt={ACFKasino.logo.title} src={ACFKasino.logo.mediaItemUrl}/>
              <div className="arvostelu">
                {ACFKasino.arvostelu == 1 && <img src="/images/star1.png" alt='Arvostelu 1/5'/>}
                {ACFKasino.arvostelu == 2 && <img src="/images/star2.png" alt='Arvostelu 2/5'/>}
                {ACFKasino.arvostelu == 3 && <img src="/images/star3.png" alt='Arvostelu 3/5'/>}
                {ACFKasino.arvostelu == 4 && <img src="/images/star4.png" alt='Arvostelu 4/5'/>}
                {ACFKasino.arvostelu == 5 && <img src="/images/star5.png" alt='Arvostelu 5/5'/>}
                {ACFKasino.arvostelu == null && <span>-</span>}
              </div>
            </div>
            <div className="why">
              <div className="list" dangerouslySetInnerHTML={{ __html: ACFKasino.miksiPelataTaalla }} /> 
            </div>
            <div className="bonus">     
              <div className="text">{ACFKasino.bonustiedot}</div>
              <button><a href={ACFKasino.affiliate} target="_blank" rel="noopener noreferrer">Pelaa nyt!</a></button>
            </div>
          </div>
          <div className="talletus">{ACFKasino.talletustavat.map(function(r, idx) {
              return(<img key={idx} src={`/images/maksu/${r.replace(" ", "").toLowerCase()}.png`} alt={r} />)
            })}
          </div>
        </header>
        {content != null &&
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: content }}/>
        }
      </article>
    </Layout>
  )
}

export const query = graphql`
  query kasino($slug: String!) {
    kasino: wpCptKasino (slug: { eq: $slug }) {
      id
      databaseId
      title
      slug
      content
      seo {
        canonical
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        title
      }
      ACFKasino {
        affiliate
        arvostelu
        bonukset
        ilmaiskierrokset
        kasinonNimi
        teksti
        logo {
          title
          mediaItemUrl
        }
        bonustiedot
        miksiPelataTaalla
        talletustavat
      }
    }
  }
`
